import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.css';

function ProjectWoodWord() {
  return (
    <Container>
      <Row className="justify-content-center text-center pad-top-5-percent">
        <h3 className="white">
        WoodWord
        </h3>
      </Row>
      <Row>
        <h4 className="white pt-2">
        About
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
      <Row>
        <p className="white pt-3">
          WoodWord is a word game where you place letters on a board and try to form words.
        </p>
        <p className="white">
          The game was made using the Unity game engine.
        </p>
      <Row>
        <h4 className="white pt-2">
        How to play
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
        <p className="white pt-3">
          <b>On desktop:</b>
        </p>
        <ol className="white pad-left-5-percent">
          <li>Simply click the button below and play in your browser</li>
        </ol>
        <p className="white">
          <b>On iOS:</b>
        </p>
        <ol className="white pad-left-5-percent">
          <li>Click the button below</li>
          <li>Tap the share button in your browser's toolbar</li>
          <li>Select the option that says "Add to Home Screen"</li>
        </ol>
        <p className="white">
          <b>On Android:</b>
        </p>
        <ol className="white pad-left-5-percent">
          <li>Click the button below</li>
          <li>Tap the three dots in your browser's toolbar</li>
          <li>Select the option that says "Add to Home screen"</li>
        </ol>
      </Row>
      <Row className="justify-content-center pad-top-5-percent">
        <Col xs={12} md={4} className="text-center">
          <Button
            href="/projects/woodword/play"
            target="_blank"
            rel="noopener noreferrer"
            variant="outline-light">
              Play in browser
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ProjectWoodWord;
