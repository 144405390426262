import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.css';

import resumePDF from './../res/Grant Matthews Resume 2023 (no phone number).pdf';
import resumeSVG from './../res/Grant Matthews Resume 2023 (no phone number).svg';

function Resume() {
  return (
    <Container>

      <Row className="pad-top-10px justify-content-center">
        <Col xs={12} className="text-center justify-content-center">
          <Image fluid className="rounded-corners-5px" src={resumeSVG}/>
        </Col>
      </Row>
      <Row className="pad-top-10px justify-content-center">
        <Col className="text-center" xs={12} md={4}>
          <a href={resumePDF} download="Grant Matthews Resume" target='_blank'>
            <Button variant="outline-light">
              Download as PDF
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Resume;
