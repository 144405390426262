import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.css';

function Projects() {
  return (
    <Container fluid className="p-0">
      <Row className="justify-content-center pt-5">
        <Col className="justify-content-center text-center">
          <p className="white">Select one of the projects from the dropdown to learn more.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Projects;
