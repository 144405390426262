import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.css';

function ProjectGearDeerAdmin() {
  return (
    <Container>
      <Row className="justify-content-center text-center pad-top-5-percent">
        <h3 className="white">
        Gear Deer Admin
        </h3>
      </Row>
      <Row>
        <h4 className="white pt-2">
        About
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
      <Row>
        <p className="white pt-3">
          Gear Deer is an online community where people can adopt and breed virtual pets. There are currently over 100 users and over 1,000 hand-crafted pets.
        </p>
        <p className="white">
          Gear Deer is run by a hard-working group of volunteer staff members. I created the Gear Deer Admin website to make their jobs easier. The website allows staff members to automatically breed pets, generate forum posts, and determine the outcomes of actions performed by community members. 
        </p>
        <p className="white">
          I built the website in 2022 and I am still actively maintaining it.
        </p>
      </Row>
      <Row>
        <h4 className="white pt-3">
        Technology
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
      <Row>
        <ul className="white pt-3 pad-left-5-percent">
          <li>Built using React</li>
          <li>Data retrieved from Google Sheets using the Sheets REST API</li>
          <li>Running on NodeJS</li>
          <li>Hosted on Microsoft Azure</li>
        </ul>
      </Row>
      <Row className="justify-content-center pad-top-5-percent">
        <Col xs={12} md={4} className="text-center">
          <Button
            href="https://gear-deer.com/admin"
            target="_blank"
            rel="noopener noreferrer"
            variant="outline-light">
              Visit the Gear Deer Admin website
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ProjectGearDeerAdmin;
