import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { SocialIcon } from 'react-social-icons';
import 'bootstrap/dist/css/bootstrap.css';

import headshot from './../res/headshot_cropped.jpg';

function About() {
  return (
    <Container>
      <Row className="mt-60 justify-content-evenly">
        <Col xs={8} md={6} lg={4} className="centered-text">
          <Image fluid roundedCircle className="pad-top-10-percent" src={headshot}/>
          <Row className="justify-content-center pad-top-10px">
            <SocialIcon fgColor="white" className="margin-x-10px" url="https://www.linkedin.com/in/grantcmatthews/" />
            <SocialIcon fgColor="white" className="margin-x-10px" url="https://github.com/gcmvy3" />
            <SocialIcon fgColor="white" className="margin-x-10px" url="mailto:grantcmatthews@gmail.com" />
          </Row>
        </Col>
        <Col sm={12} md={6} lg={8} className="pad-top-10-percent">
          <h4 className="white centered-text">
            About Me
          </h4>
          <p className="white centered-text">
            I'm a software engineer who loves to make cool things. I made this website to show off my work!
          </p>
          <p className="white centered-text">
            Head over to the Resumé page to see my professional experience, or check out some of my side projects under the Projects tab.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
