import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Unity, useUnityContext } from "react-unity-webgl";

import 'bootstrap/dist/css/bootstrap.css';

function ProjectWoodWordPlay() {

  const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
    loaderUrl: "https://gmatthewsgamestorage.blob.core.windows.net/woodword/woodword.loader.js",
    dataUrl: "https://gmatthewsgamestorage.blob.core.windows.net/woodword/woodword.data.br",
    frameworkUrl: "https://gmatthewsgamestorage.blob.core.windows.net/woodword/woodword.framework.js.br",
    codeUrl: "https://gmatthewsgamestorage.blob.core.windows.net/woodword/woodword.wasm.br",
  });

  // Store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  useEffect(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  return (
    <Container fluid className="p-0 height-100-percent">
      <Row className="p-0 m-0 centered-text align-items-center justify-content-center height-100-percent">
        <Col xs={12} className="p-0 align-items-center justify-content-center height-100-percent">
          {!isLoaded && (
            <div>
              <p className="font-calibri white">Loading Application... {Math.round(loadingProgression * 100)}%</p>
            </div>
          )}
          <Unity
            unityProvider={unityProvider}
            devicePixelRatio={devicePixelRatio}
            style={{ width: "100%", height: "100%", visibility: isLoaded ? "visible" : "hidden" }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ProjectWoodWordPlay;
