import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.css';

function ProjectTentaclimb() {
  return (
    <Container>
      <Row className="justify-content-center text-center pad-top-5-percent">
        <h3 className="white">
        Tentaclimb
        </h3>
      </Row>
      <Row>
        <h4 className="white pt-2">
        About
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
      <Row>
        <p className="white pt-3">
          Tentaclimb is a couch co-op game where 1-4 players must work together to move an octopus through the level.
        </p>
        <p className="white">
          The game was made using the Unity game engine. I worked on Tentaclimb in my spare time from April 2021 through June 2022. Development is currently paused but it may resume in the future.
        </p>
      </Row>
      <Row>
        <h4 className="white pt-3">
        How to play
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
      <Row>
        <p className="white pt-3">
          The game is not finished, but you can try it out on Windows by clicking the button below and downloading it from GitHub.
        </p>
        <p className="white">
          <b>Note: Mouse and keyboard controls are not supported. You will need to connect a controller for each player.</b>
        </p>
      </Row>
      <Row className="justify-content-center pad-top-5-percent">
        <Col xs={12} md={4} className="text-center">
          <Button
            href="https://github.com/gcmvy3/Tentaclimb-builds"
            target="_blank"
            rel="noopener noreferrer"
            variant="outline-light">
              Visit the GitHub page
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ProjectTentaclimb;
