import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap'
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.css';
import './../styles/App.css';

function AppHeader() {
  return (
    <div className="dark-background">
        <Navbar bg="dark" variant="dark" expand="md" className="justify-content-between">
            <Container className="justify-content-between">
                <Navbar.Brand>Grant Matthews</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <LinkContainer to="/resume">
                        <Nav.Link>Resumé</Nav.Link>
                    </LinkContainer>
                    <NavDropdown menuVariant="dark" title="Projects">
                        <NavDropdown.Header>Websites</NavDropdown.Header>
                        <LinkContainer to="/projects/gear-deer-admin">
                            <NavDropdown.Item>Gear Deer Admin</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/projects/grantmatthews.dev">
                            <NavDropdown.Item>GrantMatthews.dev</NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Header>Games</NavDropdown.Header>
                        <LinkContainer to="/projects/tentaclimb">
                            <NavDropdown.Item>Tentaclimb</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/projects/railyard">
                            <NavDropdown.Item>Railyard</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/projects/grantware">
                            <NavDropdown.Item>GrantWare</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/projects/woodword">
                            <NavDropdown.Item>WoodWord</NavDropdown.Item>
                        </LinkContainer>
                    </NavDropdown>
                    <LinkContainer to="/about">
                        <Nav.Link>About</Nav.Link>
                    </LinkContainer>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
  );
}

export default AppHeader;
