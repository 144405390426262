import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import About from './components/About';
import Resume from './components/Resume';
import PageWithNavbar from './components/PageWithNavbar';
import PageWithoutNavbar from './components/PageWithoutNavbar';
import Projects from './components/Projects';
import ProjectGrantMatthewsDotDev from './components/projects/ProjectGrantMatthewsDotDev';
import ProjectGearDeerAdmin from './components/projects/ProjectGearDeerAdmin';
import ProjectTentaclimb from './components/projects/ProjectTentaclimb';
import ProjectRailyard from './components/projects/ProjectRailyard';
import ProjectRailyardPlay from './components/projects/ProjectRailyardPlay';
import ProjectGrantWare from './components/projects/ProjectGrantWare';
import ProjectGrantWarePlay from './components/projects/ProjectGrantWarePlay';
import ProjectWoodWord from './components/projects/ProjectWoodWord';
import ProjectWoodWordPlay from './components/projects/ProjectWoodWordPlay';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import './styles/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

document.body.style.backgroundColor = "black";

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='*' element={<Navigate to="/resume" />} />
        <Route path="/" element={<Navigate to="/resume" />} />
        <Route path="/railyard" element={<Navigate to="/projects/railyard/play" />} />
        <Route element={<PageWithNavbar />}>
          <Route path="/projects" Component={Projects} />
          <Route path="/projects/grantmatthews.dev" Component={ProjectGrantMatthewsDotDev} />
          <Route path="/projects/gear-deer-admin" Component={ProjectGearDeerAdmin} />
          <Route path="/projects/tentaclimb" Component={ProjectTentaclimb} />
          <Route path="/projects/railyard" Component={ProjectRailyard} />
          <Route path="/projects/grantware" Component={ProjectGrantWare} />
          <Route path="/projects/woodword" Component={ProjectWoodWord} />
          <Route path="/resume" Component={Resume} />
          <Route path="/about" Component={About} />
        </Route>
        <Route element={<PageWithoutNavbar />}>
          <Route path="/projects/railyard/play" Component={ProjectRailyardPlay} />
          <Route path="/projects/grantware/play" Component={ProjectGrantWarePlay} />
          <Route path="/projects/woodword/play" Component={ProjectWoodWordPlay} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
