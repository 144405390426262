import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.css';

function ProjectGrantMatthewsDotDev() {
  return (
    <Container>
      <Row className="justify-content-center text-center pad-top-5-percent">
        <h3 className="white">
        GrantMatthews.dev
        </h3>
      </Row>
      <Row>
        <h4 className="white pt-2">
        About
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
      <Row>
        <p className="white pt-3">
          GrantMatthews.dev is my personal portfolio website. You're using it right now!
        </p>
      </Row>
      <Row>
        <h4 className="white pt-3">
        Technology
        </h4>
        <hr className="horizontal-white-line"/>
      </Row>
      <Row>
        <ul className="white pt-3 pad-left-5-percent">
          <li>Built using React Bootstrap (no templates!)</li>
          <li>Running on NodeJS</li>
          <li>Hosted on Microsoft Azure</li>
        </ul>
      </Row>
    </Container>
  );
}

export default ProjectGrantMatthewsDotDev;
