import React from 'react';
import AppHeader from './AppHeader';
import { Outlet } from 'react-router';

export default () => {
  return (
    <>
      <AppHeader />
      <Outlet />
    </>
  );
};